<template>
    <header>
        <template v-if="Object.keys(data).length">
            <div class="info">
                <figure>
                    <img v-if="ticketImage" :src="imgURL + ticketImage.image" alt="product">
                    <span v-else>{{data.title.charAt(0)}}</span>
                </figure>
                <div class="data">
                    <h2>{{data.title}}</h2>
                    <div class="assigned">
                        <span class="date">Created {{timeSince(data.updated_at)}}
                            <span v-if="mainTab.value !== 1" class="username">by {{data.ticket_raised.name}}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="actions">
                <figure  v-if="!type.length">
                    <img src="/assets/images/phone-icon.png" alt="start call" @click="startCall" />
                </figure>
                <figure class="options" @click="$emit('editTicket')">
                    <img  src="/assets/images/black_dots.svg" alt="options" />
                </figure>
            </div>
        </template>
        <template v-else>
            <span>Loading...</span>
        </template>
    </header>
</template>

<script>
import nodeInstance from '@/services/NodeServiceinstance'
import timeSince from '@/mixins/timeSince'
import { mapMutations } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            required: false,
            default: () => {}
        },
        type: {
            required: false,
            type: Array,
            default: () => [],
        },
    },

    mixins: [timeSince],

    data() {
        return {
            imgURL: process.env.VUE_APP_DO_SPACES,
            roomId: undefined,
            user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
        }
    },

    inject: ['mainTab'],

    computed: {
        ticketImage() {
            return this.data?.product?.product_images?.[0]
        },
    },

    watch: {
        data(val) {
            if(val) this.getRoom()
        }
    },

    methods: {
        ...mapMutations({
            setCallStatus: "call/setCallStatus",
            setCallId: "call/setCallId",
        }),

        async getRoom() {
            const response = await nodeInstance.get(`/chats/list?ticketId=${this.data.id}&page=1`)
            this.roomId = response.data.room
        },

        startCall() {
            let userToSend = this.user;
            userToSend.type = "single";
            const params = {
                roomId: this.roomId,
                with: userToSend,
            };
            this.setCallStatus(true);
            this.setCallId(this.roomId);
            this.emitter.emit("request-to-join-call", params);
            this.emitter.emit("video-section-prepared", params);
        },
    },
}
</script>

<style lang="scss" scoped>
    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 1rem;
        border-bottom: solid 0.2rem #f2f5f8;
        height: 4rem;

        .info {
            display: flex;
            gap: 1rem;
            align-items: center;

            figure {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    background: var(--primary-color);
                    color: white;
                    font-weight: 500;
                    cursor: pointer;
                    user-select: none;
                }
            }

            .data {
                display: flex;
                flex-direction: column;

                h2 {
                    font-size: 1.2rem;
                    font-weight: 600;
                    margin: unset;
                }

                .assigned {
                    display: flex;
                    gap: 0.2rem;
                    font-weight: 500;
                    font-size: 0.8rem;

                    .date {
                        color: $grey;
                        
                        .username {
                            cursor: pointer;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .actions {
            display: flex;
            align-items: center;
            gap: 2rem;
            height: 100%;

            figure {
                user-select: none;
                user-drag: none;
                -webkit-user-drag: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                height: 100%;
            }

            .close {
                border: solid 1px $red;
                border-radius: 30px;
                padding: 0.2rem 0.5rem;
                color: $red;
                user-select: none;
                cursor: pointer;
                transition: 0.2s;

                &:hover {
                    color: white;
                    background: $red;
                }

                span {
                    text-transform: capitalize;
                }
            }
        }
    }
</style>