<template>
    <section class="tickets">
        <template v-for="(item, i) in data" :key="i">
            <article @click="$emit('select', item)">
                <Ticket :data="item" :unreads="hasUnreads(item)"/>
            </article>
        </template>
    </section>
</template>

<script>
import nodeInstance from '@/services/NodeServiceinstance'
import Ticket from './Ticket.vue'

export default {
    props: {
        data: {
            type: Array,
            required: false,
            default: () => []
        },
    },

    data() {
        return {
            user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
            ticketUnreads: [],
            loaded: false,
        }
    },

    components: {
        Ticket
    },

    methods: {
        async getUnreads() {
            const tickets = this.data.filter(el => !el.type).map(el => el.id)
            if (tickets.length) {
                const response = await nodeInstance.get(`/chats/ticketUnreadCounts?userId=${this.user.id}&ticketIds=${tickets}`)
                this.ticketUnreads = response.data
            }
            
        },

        hasUnreads(item) {
            return this.ticketUnreads.filter(el => el.ticketId === item.id)
        }
    },

    mounted() {
        this.getUnreads()
    }
}
</script>

<style lang="scss" scoped>
    .tickets {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        article {
            width: 25rem;
            background: white;
            display: flex;
            cursor: pointer;
            transition: 0.2s;
            border-radius: 2px;
            overflow: hidden;
            position: relative;
            box-shadow: 0 2px 1px rgba(0,0,0,0.1);

            &:hover {
                background: #efefef;
            }

            &:last-of-type {
                margin-bottom: unset;
            }
        }
    }
</style>