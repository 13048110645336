<template>
    <main>
        <header>
            <div class="row">
                <h3>My Tickets</h3>
                <button
                    class="btn"
                    @click.prevent="create = true"
                >
                    Create Ticket
                </button>
            </div>
            <div class="tabs">
                <span v-if="canCreate.includes(getUserProfile.role.id)" :class="{active: activeTab === 0}" @click="activeTab = 0">Assigned to me</span>
                <span :class="{active: activeTab === 1}" @click="activeTab = 1">Created by me</span>
                <span v-if="canCreate.includes(getUserProfile.role.id)" :class="{active: activeTab === 2}" @click="activeTab = 2">Support Chats</span>
                <span v-if="canCreate.includes(getUserProfile.role.id)" :class="{active: activeTab === 3}" @click="activeTab = 3">Internal Chats</span>
            </div>
        </header>
        <div class="tickets-content" :class="{chat: loaded && entries.length && Object.keys(selectedTicket).length}">
            <template v-if="loaded && entries.length">
                <Chat v-if="Object.keys(selectedTicket).length" :selected="selectedTicket" @back="selectedTicket = {}"/>
                <Tickets v-else :data="entries" @select="handleSelect"/>
            </template>
            <template v-else-if="loaded && !entries.length">
                <span>You have no tickets on this section</span>
            </template>
            <template v-else-if="!loaded">
                <div class="loading">
                    <span class="loader"></span>
                </div>
            </template>
        </div>
    </main>
    <CreateTicket
        v-if="create"
        :title="'Create Ticket'"
        @create="handleCreate"
        @close="create = false"
    />
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
// import analyticsInstance from "@/services/AnalyticsInstance";
import CreateTicket from "@/components/Create/Tickets/Index.vue";
import Tickets from './Sections/Tickets/Index.vue'
import Chat from './Sections/Chat/Index.vue'
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import { mapGetters, mapActions } from 'vuex'
import { computed } from 'vue'
import api from "@/mixins/optionsApi";

export default {
    components: {
        Tickets,
        Chat,
        CreateTicket
    },

    data() {
        return {
            client: localStorage.client ? JSON.parse(localStorage.client) : {},
            user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
            totalPages: 1,
            currentPage: 1,
            entries: [],
            selectedTicket: {},
            loaded: false,
            canCreate: [4, 5, 6, 7, 8],
            activeTab: 1,
            create: false,
        }
    },

    mixins: [api],

    provide() {
        return {
            mainTab: computed(() => this.activeTab)
        };
    },

    watch: {
        activeTab: {
            immediate: true,
            handler(val) {
                this.resetData()
                
                if (val === 2) {
                    this.getSupportnInternalChats('support')
                    return
                }
                
                if (val === 3) {
                    this.getSupportnInternalChats('internal')
                    return
                }

                this.getTickets()
            }
        }
    },

    computed: {
        ...mapGetters("account", {
            getUserProfile: GET_USER_PROFILE_GETTER,
        }),

        toCreate() {
            return [
                {
                    type: "text",
                    label: "Title",
                },
                {
                    type: "textarea",
                    label: "Description",
                },
                {
                    type: "select",
                    label: "Site",
                    options: this.api("sites"),
                    link: {
                        to: "products",
                    },
                },
                {
                    type: "select-linked",
                    label: "Product",
                    options: this.api("products"),
                    link: {
                        to: "Site",
                        entry: "products",
                    },
                },
            ];
        }
    },

    methods: {
        ...mapActions({
            storeAnalytics: 'analytics/storeAnalytics',
        }),

        async getTickets() {
            const filter = !this.activeTab ? 'myTickets': 'ownTickets'

            const response = await axiosInstance.get(`new-ticket-list?org_id=${this.org.id}&${filter}=yes&clientId=${this.client.id || ''}&page=${this.currentPage}`).finally(() => this.loaded = true)
            const data = response.data.data

            this.treatData(data)
        },

        async getSupportnInternalChats(type) {
            const response = await axiosInstance.get(`additional-ticket-chats?org_id=${this.org.id}&type=${type}`).finally(() =>  this.loaded = true)
            
            const initData = response.data.data.ticketList
            const data = {
                data: initData.map(el => {
                    return {
                        id: el.ticketId,
                        title: el.ticketName,
                        ticket_raised: {
                            id: el.creatorId,
                            avatar: el.creatorImage,
                            name: el.creatorName
                        },
                        client: {
                            id: el.clientId,
                            name: el.clientName
                        },
                        product: {
                            id: el.productId,
                            name: el.productName
                        },
                        ticket_priority:{
                            priority_details: {
                                name: 'low'
                            }
                        },
                        type: el.ticketType,
                        room: el.roomId
                    }
                }),
                last_page: 1
            }

            this.treatData(data)
        },

        treatData(data) {
            this.totalPages = data.last_page
            this.entries = [...data.data]
        },

        handleSelect(item) {
            this.selectedTicket = item
        },

        resetData() {
            this.totalPages = 1
            this.currentPage = 1
            this.selectedTicket = {}
            this.entries = []
            this.loaded = false
        },

        async handleCreate(data) {
            const toSend = {
                id_organization: this.org.id,
                title: data.title,
                description: data.description,
                id_project: data.site?.id || null,
                id_product: data.product?.id || null,
                id_user: this.user.id,
                ticket_questions: data.answers ? data.answers.filter(el => el.id).map(el => {
                return {
                    q_id: el.q_id,
                    a_id: el.id
                }
                }) : []
            };

            if (this.canCreate.includes(this.getUserProfile.role.id)) toSend.create_for = 'client'

            const response = await axiosInstance.post(`add-ticket`, toSend);

            if (response.status === 200) {
                // const analyticsInfo = {
                //     action: "create-ticket",
                //     environment: process.env.VUE_APP_ENVIRONMENT,
                //     org_id: this.org.id,
                //     ticket_id: response.data.data,
                //     user_id: this.user.id,
                //     product_id: data.product?.id,
                //     category_id: data.product?.category?.id,
                //     site_id: data.site?.id,
                //     client_id: this.client?.id || '',
                //     priority_id: 1,
                //     device: 'Desktop',
                //     is_client_user: this.canCreate.includes(this.getUserProfile.role.id)
                // }

                // console.log(analyticsInfo)

                // this.storeAnalytics(analyticsInfo)

                // const storeAnalytics = await analyticsInstance.post('storeData', analyticsInfo)
                // console.log(storeAnalytics)

                this.create = false

                const toCreateRoom = {
                    type: "ticket",
                    ticketId: response.data.data.ticketId,
                    organizationId: this.org.id,
                }

                if (this.canCreate.includes(this.getUserProfile.role.id)) this.toCreateRoom.ownerId = response.data.data.userId

                this.emitter.emit("create-room", toCreateRoom);

                this.emitter.emit("alert", response.data.message);

                if(this.activeTab === 1) {
                    this.resetData()
                    this.getTickets()
                }
                else this.activeTab = 1
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    main {
        display: flex;
        flex-direction: column;
        width: 100%;

        header {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.5rem;

            .row {
                display: flex;
                justify-content: space-between;

                h2 {
                    font-weight: 600;
                }

                button {
                    height: fit-content;
                }
            }

            .tabs {
                display: flex;
                
                span {
                    user-select: none;
                    cursor: pointer;
                    position: relative;
                    width: 8rem;
                    transition: 0.2s;

                    &:hover {
                        color: #000000bb;
                    }

                    &.active {
                        font-weight: 600;
                        color: var(--primary-color)
                    }

                    &:not(:first-of-type) {
                        margin-left: 1rem;
                    }
                }
            }
        }

        .tickets-content {
            display: flex;
            width: 100%;
            margin-bottom: 1.5rem;

            @media only screen and (max-width: 768px) {
                flex-direction: column;
            }

            &.chat {
                box-shadow: 0 0 6px rgba(0,0,0,0.1);
            }
        }
    }

    .loading {
        width: 100%;
        height: 70vh;
        background: none;
    }
</style>